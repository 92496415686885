import React from 'react';
import '../App.css';
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: 600,
        margin: 'auto'
    },
    header: {
        textAlign: 'center'
    },
    formControl: {
        margin: theme.spacing(3),
    },
    button: {
        margin: theme.spacing(1, 1, 0, 0),
    },
    paper: {
        marginLeft: '2%',
        marginRight: '2%',
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));


export default function Home() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container alignContent={'center'} justify={'center'} spacing={3} style={{marginTop: 20}}>
                {[
                    {title:'Lausanne', link:'lausanne'},
                    {title:'Ecublens', link:'ecublens'},
                    {title:'Vaud', link:'vaud'},
                    {title:'Fédéral', link:'federal'}
                ].map(s =>
                    <Grid item xs={12} key={s.link}>
                        <Link to={`/quizz/${s.link}`} style={{textDecoration: 'inherit'}}>
                            <Paper className={classes.paper}>{s.title}</Paper>
                        </Link>
                    </Grid>
                )}
            </Grid>
        </div>
    );
}

