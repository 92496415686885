import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import {Link} from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {Button} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white'
    },
    leftMenuIcon:{
        minWidth: 35
    },
    leftMenuText: {

    },
    logo: {
        width: 230,
        [theme.breakpoints.down('sm')]: {
            width: '50%',
        }
    },
    lngOption: {
        paddingLeft: 10,
        paddingRight: 10
    }

}));

export default function Header(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div style={{width: '15%'}}>
                <Link to={'/'} style={{ color: 'inherit', textDecoration: 'inherit'}}>
                    <IconButton onClick={() => {console.log('clickIcon')}} color="inherit" aria-label="Menu">
                        <ArrowBackIcon />
                    </IconButton>
                </Link>
            </div>
            <div style={{width: '70%', textAlign:'center', fontWeight: 700}}>
                {props.title}
            </div>
            <div style={{width: '15%'}}>
                {/*<Button onClick={props.nextAction}>Next</Button>*/}
                {''}
            </div>
        </div>
    );
}
