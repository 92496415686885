import React, {useEffect, useState} from 'react';
import {Grid} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Link} from "react-router-dom";
import Radio from "@material-ui/core/Radio";
import {useParams} from "react-router-dom";
import Header from "../components/Header";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 600,
        margin: 'auto'
    },
    header: {
        textAlign: 'center'
    },
    formControl: {
        margin: theme.spacing(3),
        width: '90%'
    },
    button: {
        margin: theme.spacing(1, 1, 0, 0),
    },
    radioGroup: {
        textAlign: 'left',
        backgroundColor: 'white',
        padding: 15,
        borderRadius: 10
    },
    label: {
        textAlign: 'left',
        padding: 20
    }
}));

function shuffle(a): any[] {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function QuizzPage() {
    const classes = useStyles();
    let {quizSource} = useParams();
    const [quizJson, setQuizJson] = useState([]);
    const [quizIndex, setQuizIndex] = useState(0);
    const [positives, setPositives] = useState(0);
    const [negatives, setNegatives] = useState(0);
    const [value, setValue] = React.useState('');
    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState('Choose wisely');

    useEffect(() => {
        console.log('trying to load...', quizSource);
        fetch(`${process.env.PUBLIC_URL}/${quizSource}.json`, {cache: "no-cache"})
            .then(resp => resp.json())
            .then((data) => {
                const categories = shuffle(Object.keys(data));
                const randomQuiz = [] as any;
                for (const c of categories) {
                    const questions: any[] = shuffle(data[c]);
                    for (const q of questions) {
                        randomQuiz.push(
                            {
                                category: c,
                                question: q.question,
                                options: shuffle(q.options)
                            }
                        );
                    }
                }
                setQuizJson(randomQuiz);
                setQuizIndex(0);
            });
    }, []);


    const getQuestionItem = () => {
        if (quizJson.length === 0) {
            return;
        }
        const element: any = quizJson[quizIndex];

        return (
            <Grid item container xs={12} className={classes.header} justify={'center'} alignContent={'center'}
                  alignItems={'center'}>
                <Grid style={{width: '100%', padding: 10}}>
                    <Typography style={{paddingBottom: 15, color: '#666', paddingTop: 0}}>{element.category}</Typography>
                    <Typography
                        style={{paddingBottom: 15, color: 'black', textAlign: 'left'}}>{element.question}</Typography>
                    <RadioGroup aria-label="quiz" name="quiz" value={value} className={classes.radioGroup}
                                onChange={handleRadioChange}>
                        {element.options.map(o => {
                                const optionValues = o.split('|');
                                if (value.startsWith('0|')) {
                                    return (<FormControlLabel value={o} control={<Radio color='default' className={classes.label}/>}
                                                              label={optionValues[1]}
                                                              style={{
                                                                  backgroundColor: (optionValues[0] === '0' ?
                                                                      '#ffd6cc' : '#d9f2d9')
                                                              }}
                                    />);

                                } else {
                                    return (
                                        <FormControlLabel value={o} control={<Radio color='default' className={classes.label}/>}
                                                          label={optionValues[1]}
                                        />
                                    )
                                }
                            }
                        )}
                    </RadioGroup>
                </Grid>
            </Grid>
        )
    }

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        setValue(value);
        if (value.startsWith('1|')) {
            setHelperText('');
            setError(false);
        } else {
            setHelperText('Sorry, wrong answer!');
            setError(true);
        }
    };

    const handleNextAction = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        if (value.startsWith('1|')) {
            setPositives(positives + 1);
        } else {
            setNegatives(negatives + 1);
        }
        setHelperText('');
        setError(false);
        setValue('');
        setQuizIndex(quizIndex + 1);

    };

    const restart = () => {
        setQuizIndex(0);
        setPositives(0);
        setNegatives(0);
    }

    if (quizIndex === quizJson.length) {
        return (<div className={classes.root}>
                <Header title={capitalizeFirstLetter(quizSource)} nextAction={handleNextAction}/>
                <Grid
                    container
                    direction="row"
                    justify={"center"}
                    alignItems={"center"}
                    alignContent={'center'}
                >
                    <Grid item style={{textAlign: 'center'}}>
                        FINISHED!
                        <br/>
                        <br/>
                        <Typography>Positives: {positives}/{quizJson.length}</Typography>
                        <Typography>Negatives: {negatives}/{quizJson.length}</Typography>
                        <br/>
                        <br/>
                        <div>
                            <Button onClick={restart}>Start again</Button>
                            <Link to={'/'} style={{textDecoration: 'inherit'}}>
                                <Button>Home</Button>
                            </Link>
                        </div>

                    </Grid>
                </Grid>
            </div>
        );
    }

    return (<div className={classes.root}>
            <Header title={capitalizeFirstLetter(quizSource)} nextAction={handleNextAction}/>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                {getQuestionItem()}
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Typography
                        style={{color: '#333', fontSize: 11, paddingBottom: 10}}
                    >Progress: {quizIndex}/{quizJson.length}</Typography>
                    {value !== '' ?
                        (<div onClick={handleNextAction} style={{cursor: 'pointer'}}>
                            <div>
                                {
                                    (value.startsWith('1|') ?
                                            <CheckCircleIcon style={{fontSize: '5rem', color: '#339933'}}/>
                                            :
                                            <CancelIcon style={{fontSize: '5rem', color: '#cc0000'}}/>
                                    )
                                }
                            </div>
                            <div style={{textAlign: 'center'}}>
                                <Button style={{backgroundColor: '#ccc'}}>Next</Button>
                            </div>
                        </div>)
                        : ''
                    }
                </Grid>
            </Grid>


        </div>
    );
}
